@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300&family=Orbitron:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .neon-button {
        @apply font-chakra border-2 rounded-md p-1 absolute hidden sm:inline text-sm xl:text-base bg-gray-900
    }
    .button-glow {
        text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.2), 0 0 0.45em currentColor;
    }
}